import PropTypes from 'prop-types';
import React from 'react';

const Loading = ({ extraClasses }) => (
  <div className={`c-loading ${extraClasses || ''}`}>
    <div className="c-loading__overlay">
      <svg
        width="40"
        height="40"
        viewBox="0 0 20 20"
        className="c-loading__spinner"
      >
        <circle
          cx="10"
          cy="10"
          r="8"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          pathLength="10"
          strokeDasharray="8 2"
          strokeLinecap="round"
        />
      </svg>
    </div>
  </div>
);

Loading.propTypes = {
  extraClasses: PropTypes.string,
};

export default Loading;
