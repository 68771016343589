import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import Button from 'components/Button';
import ChevronLeft from 'svg/double-chevron-left.svg';
import ChevronRight from 'svg/double-chevron-right.svg';

const Pagination = ({ currentPage, totalPages, onPrev, onNext, loading }) => {
  const { t } = useTranslation();

  if (totalPages < 2) return null;

  return (
    <div className="c-pagination">
      <Button
        type="button"
        label={t('pagination.prev')}
        test="pagination-prev"
        disabled={currentPage === 1 || loading}
        onClick={() => {
          if (onPrev) onPrev();
        }}
        IconLeft={ChevronLeft}
        extraClasses="c-pagination__button c-pagination__button--prev"
      />

      <span className="c-pagination__summary" data-cy="pagination-summary">
        {t('pagination.currentPage', {
          current: currentPage,
          total: totalPages,
        })}
      </span>

      <Button
        type="button"
        label={t('pagination.next')}
        test="pagination-next"
        disabled={currentPage === totalPages || loading}
        onClick={() => {
          if (onNext) onNext();
        }}
        IconRight={ChevronRight}
        extraClasses="c-pagination__button c-pagination__button--next"
      />
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  loading: PropTypes.bool,
};

export default Pagination;
