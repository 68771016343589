import PropTypes from 'prop-types';

const IconHeading = ({ Icon, HeadingTag, heading }) => (
  <div className="c-icon-heading">
    <div className="c-icon-heading__icon-container">
      <Icon className="c-icon-heading__icon" width={24} height={24} />
    </div>
    <HeadingTag className="c-icon-heading__heading">{heading}</HeadingTag>
  </div>
);

IconHeading.propTypes = {
  Icon: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  HeadingTag: PropTypes.string,
};

IconHeading.defaultProps = {
  HeadingTag: 'h2',
};

export default IconHeading;
