import PropTypes from 'prop-types';
import Card from 'components/Card';
import { getStrippedEntityId } from 'lib/entityIds';
import { JobPostingType } from 'lib/proptypes/jobPosting';
import { slugifyVacancy } from 'lib/slugify';

export default function JobPosting({
  isActive = false,
  compact = false,
  extraData,
  cta,
  onClick,
  jobPosting: {
    node: {
      id,
      title,
      slug,
      description,
      employmentType: { hours },
      companyBranch: { company },
      address: { location },
      vacancyStats,
    },
  },
}) {
  const currentItem = extraData?.find?.(item => item.id === `/vacancies/${id}`);
  const views = vacancyStats?.views || currentItem?.views;

  return (
    <Card
      extraClasses={isActive ? 'c-card--active-job-posting' : ''}
      title={title}
      ctaTag="a"
      href={`/vacature/${slugifyVacancy(slug, location)}/${getStrippedEntityId(
        id
      )}`}
      onClick={onClick}
      company={company}
      location={location}
      hours={hours}
      views={
        !compact && typeof views === 'number'
          ? vacancyStats?.views || currentItem?.views
          : undefined
      }
      description={!compact && description ? description : ''}
      cta={!compact && cta ? cta : ''}
      test="vacancy-item"
      dataId={id}
    />
  );
}

JobPosting.propTypes = {
  jobPosting: JobPostingType,
  compact: PropTypes.bool,
  isActive: PropTypes.bool,
  cta: PropTypes.string,
};
