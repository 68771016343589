export const createJobPostingGhosts = (amount = 0) => {
  const arr = [];
  for (let i = 0; i < amount; i += 1) {
    const obj = {};
    ['title', 'company', 'location', 'hours'].map(p => {
      const length =
        p === 'title'
          ? 10 + Math.round(Math.random() * 20)
          : 8 + Math.round(Math.random() * 8);
      let string = '';
      for (let j = 0; j < length; j += 1) {
        string += String.fromCharCode(32 + Math.round(Math.random() * 94));
      }
      obj[p] = string;
      return string;
    });

    arr.push(obj);
  }
  return arr;
};
