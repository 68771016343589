import { print } from 'graphql';
import { SINGLE_JOB_POSTING } from './queries';
import { setGraphqlVacancyId } from './entityIds';

export async function getJobPosting(id) {
  return fetch(process.env.GRAPHQL_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: print(SINGLE_JOB_POSTING),
      variables: { id: setGraphqlVacancyId(id) },
    }),
  })
    .then(async response => {
      if (!response.ok) {
        return false;
      }

      const { data } = await response.json();

      if (!data.vacancy) {
        const duplicatedVacancy = await fetch(
          `${process.env.API_ENDPOINT}/vacancies/get-new-url/${id}`
        ).then(resp => resp.json());
        // If the current vacancy was duplicated
        if (duplicatedVacancy.id) {
          return false;
        }

        return false;
      }

      return data.vacancy;
    })
    .catch(error => {
      console.error(error);
      return false;
    });
}
