const mapElasticJobPostings = elasticJobPostings => {
  if (!elasticJobPostings) return [];

  return elasticJobPostings.map(
    ({
      _source: {
        id,
        title,
        slug,
        description,
        views,
        employmentType: { name: employmentType },
        address: { city },
        companyBranch: { name },
      },
    }) => ({
      node: {
        id,
        title,
        slug,
        description,
        views,
        employmentType: {
          hours: employmentType,
        },
        companyBranch: {
          company: name,
        },
        address: {
          location: city,
        },
      },
    })
  );
};

export default mapElasticJobPostings;
