import PropTypes from 'prop-types';

export const JobPostingType = PropTypes.shape({
  node: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    description: PropTypes.string,
    views: PropTypes.number,
    employmentType: PropTypes.shape({ hours: PropTypes.string.isRequired }),
    companyBranch: PropTypes.shape({
      company: PropTypes.string.isRequired,
    }),
    address: PropTypes.shape({ location: PropTypes.string.isRequired }),
  }),
});
