import JobPostingContent from '@molecules/JobPostingContent/JobPostingContent';
import Loading from 'components/Loading';
import { getJobPosting } from 'lib/getJobPosting';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

export default function JobPostingPreview({ id }) {
  const { t } = useTranslation();
  const [jobPosting, setJobPosting] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    const addView = async vacancyId => {
      await fetch(`/api/addViewToVacancy`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: vacancyId }),
      });
    };

    addView(id);
  }, [id]);

  useEffect(async () => {
    if (!id) return;

    setLoading(true);

    const jp = await getJobPosting(id);

    setJobPosting(jp);

    if (!jp) {
      setError(true);
      setLoading(false);
      return;
    }

    setError(false);
    setLoading(false);
  }, [id]);

  if (!id) {
    return <p>{t('layout.empty')}</p>;
  }

  if (loading) {
    return <Loading extraClasses="c-loading--absolute" />;
  }

  if (error) {
    return (
      <>
        <h3>{t('jobPostingDetail.error.title')}</h3>
        <p>{t('jobPostingDetail.error.description')}</p>
      </>
    );
  }

  if (jobPosting) {
    return <JobPostingContent jobPosting={jobPosting} isPreview />;
  }

  return (
    <>
      <h3>{t('jobPostingDetail.error.title')}</h3>
      <p>{t('jobPostingDetail.error.description')}</p>
    </>
  );
}

JobPostingPreview.propTypes = {
  id: PropTypes.string,
};
